import Button from 'components/Button/Button';
import InfoIcon from 'components/Icon/assets/InfoIcon';
import { PRIVATE_NAVIGATION } from 'constants/navigation.constant';
import { FormikValues } from 'formik';
import { useAxiosPost } from 'hooks/useAxios';
import { LoaderStatusEnum, StatusEnum } from 'interfaces/common.interface';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  currentInstituteSelector,
  processIdSelector,
} from 'reduxStore/slices/instituteSlice';
import {
  setCalendarLoaderStatus,
  setLoaderStatus,
  setLoaderStatusMessage,
  setPrimaryStep,
  setProgressStatus,
} from 'reduxStore/slices/loaderSlice';
import '../../index.css';

// const initialValues = {
//   infeasibility_diagnosis: true,
//   uniform_distributivity: false,
//   student_state_date: new Date(),
//   default_course_length: 31,
//   take_holiday_into_account: false,
//   take_hws_into_account: false,
// };

// const types = [
//   {
//     label: 'Infeasibility diagnosis',
//     name: 'infeasibility_diagnosis',
//     type: 'switch',
//     description: 'Infeasibility diagnosis',
//   },
//   {
//     label: 'Uniform distributivity',
//     name: 'uniform_distributivity',
//     type: 'switch',
//     description: 'Uniform distributivity',
//   },
//   {
//     label: 'Student start date',
//     name: 'student_state_date',
//     type: 'date',
//     description: 'Student start date',
//   },
//   {
//     label: 'Take holiday into account',
//     name: 'take_holiday_into_account',
//     type: 'switch',
//     description: 'Take holiday into account',
//   },
//   {
//     label: 'Default course length',
//     name: 'default_course_length',
//     type: 'input',
//     description: 'Default course length',
//   },
//   {
//     label: 'Take HWS into account',
//     name: 'take_hws_into_account',
//     type: 'switch',
//     description: 'Take HWS into account',
//   },
// ];

export default function ScheduleGeneration() {
  const [callApi, { isLoading }] = useAxiosPost();
  const currentInstitute = useSelector(currentInstituteSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const processId = useSelector(processIdSelector);
  const [resetSchedule, setResetSchedule] = useState<boolean>(true);
  const [navigateTo, setNavigateTo] = useState<string>('');

  useEffect(() => {
    navigate(navigateTo);
  }, [navigateTo]);

  useEffect(() => {
    dispatch(setCalendarLoaderStatus(false));
  }, [processId]);

  const onSubmit = async (_values: FormikValues) => {
    const response = await callApi('/schedule-process-logs', {
      instituteId: currentInstitute?.value,
      // toggleOption: 'on',
      // uniform_distributivity: true,
      // infeasibility_diagnosis: true,
      // start_date: '2024-08-10',
      // take_holidays_into_account: false,
      // default_course_length: 31,
      // take_hws_into_account: false,
      // second_logic: false,
    });
    if (response.response_type === StatusEnum.SUCCESS) {
      dispatch(setLoaderStatusMessage('Syncing...'));
      dispatch(setLoaderStatus(LoaderStatusEnum.CREATE));
      dispatch(setPrimaryStep(0));
      dispatch(setProgressStatus(0));
      setNavigateTo(PRIVATE_NAVIGATION.dataValidation.path);
    }
  };

  useEffect(() => {
    const stopApiCalls = localStorage.getItem('apiCall');
    if (stopApiCalls) {
      localStorage.setItem('apiCall', 'false');
    }
  }, []);

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-semibold text-center mb-8">Select Scheduler</h1>
      {/* <p className="text-center text-gray-600 mb-8">
        Please select any one to begin data sync
      </p> */}
      <div className="flex flex-col items-center gap-10">
        <div className="relative flex rounded-xl py-4">
          <div
            className={`absolute inset-y-0 transition-all duration-300 ease-in-out bg-orange-50 border border-primary rounded-xl w-[260px] ${
              resetSchedule ? 'left-0 right-1/2' : 'left-1/2 right-0'
            }`}
          />
          <button
            className={`relative w-[260px] h-[80px] px-4 py-2 text-sm font-medium transition-colors duration-300 rounded-xl ${
              resetSchedule ? 'text-primary' : 'text-gray-500'
            }`}
            onClick={() => setResetSchedule(true)}
          >
            <span className="flex flex-wrap items-center text-center justify-center">
              Schedule while
            </span>
            <span className="flex flex-wrap items-center text-center justify-center">
              minimizing changes
            </span>
            <InfoIcon
              className="h-4 w-4 absolute -top-2 right-2"
              title="Reset the schedule to its default state"
            />
          </button>
          <button
            className={`relative w-[260px] h-[80px] px-4 py-2 text-sm font-medium transition-colors duration-300 rounded-xl ${
              !resetSchedule ? 'text-primary' : 'text-gray-500'
            }`}
            onClick={() => setResetSchedule(false)}
          >
            <span className="flex flex-wrap items-center text-center justify-center">
              New optimized
            </span>
            <span className="flex flex-wrap items-center text-center justify-center">
              schedule
            </span>
            <InfoIcon
              className="h-4 w-4 absolute -top-2 right-2"
              title="Reset the schedule to its default state"
            />
          </button>
        </div>
        <div className="flex flex-col justify-center gap-[20px]">
          <p className="text-grayTextLight">
            The schedule will be available for you to preview before you commit it to
            Teachworks.
          </p>
          <div className="flex justify-center">
            <Button
              type="submit"
              isLoading={isLoading}
              className="bg-primary text-white px-4 py-2 rounded-md"
              onClickHandler={onSubmit}
            >
              Run Scheduler
            </Button>
          </div>
        </div>
      </div>
      {/* <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ values, setFieldValue }) => (
          <Form>
            <div className="max-w-2xl mx-auto bg-primaryLight text-grayText p-4 py-5 rounded-lg">
              <div className="">
                <div className="flex justify-between items-center pb-6 mb-6 border-b border-borderColor text-md text-black font-normal">
                  <h2>Configure your Scheduler</h2>
                  <span>Type</span>
                </div>
                <div className="space-y-8">
                  {types.map((item, index) => (
                    <div key={index} className="flex items-center justify-between">
                      <div
                        className="flex items-center space-x-2"
                        title={item.description}
                      >
                        <label htmlFor={item.name}>{item.label}</label>
                        <InfoIcon className="h-4 w-4 text-gray-400" />
                      </div>
                      {item.type === 'date' && (
                        <DatePicker
                          name={item.name}
                          selectedDate={values[item.name as keyof object]}
                          onChange={(date) => setFieldValue(item.name, date)}
                          className="h-[3rem] -mr-2"
                          icon
                          rightIcon
                        />
                      )}
                      {item.type === 'input' && (
                        <InputField
                          id={item.name}
                          name={item.name}
                          type="number"
                          placeholder="31"
                          parentClass="w-max"
                          className="text-right"
                          value={values[item.name as keyof object]}
                        />
                      )}
                      {item.type === 'switch' && (
                        <Switch
                          id={item.name}
                          checked={values[item.name as keyof object]}
                          onChange={(value: boolean) =>
                            setFieldValue(item.name, value)
                          }
                        />
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            </Form>
            )}
            </Formik> */}
    </div>
  );
}
