import Button from 'components/Button/Button';
import InputField from 'components/FormElement/InputField';
import { REACT_APP_API_URL } from 'config';
import { Form, Formik, FormikValues } from 'formik';
import { useAxiosPost } from 'hooks/useAxios';
import { ForgotPasswordValidationSchema } from 'modules/Auth/validationSchema';
import { Link, useNavigate } from 'react-router-dom';

const ForgotPassword = () => {
  const [callApi, { isLoading: forgotApiLoading }] = useAxiosPost();
  const navigate = useNavigate();
  const forgotPasswordInitialValue = { email: '' };

  const OnSubmit = async (userData: FormikValues) => {
    if (userData) {
      const forgotPasswordData = {
        email: userData.email,
      };
      const response = await callApi(
        `${REACT_APP_API_URL}/auth/forgotPassword`,
        forgotPasswordData,
        {}
      );
      if (response.data) {
        navigate('/auth/login');
      }
    }
  };

  return (
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded shadow-md w-[350px]">
      <h2 className="text-2xl font-semibold mb-6 text-center">Forgot Password</h2>
      <Formik
        initialValues={forgotPasswordInitialValue}
        validationSchema={ForgotPasswordValidationSchema()}
        onSubmit={(values) => OnSubmit(values)}
      >
        <Form>
          <InputField
            name="email"
            label="Email"
            labelClass="block text-gray-700 text-sm font-bold mb-2"
            placeholder="Enter your email"
            type="text"
          />
          <div className="flex my-4 w-full gap-2 justify-center col-span-2">
            <Button
              isLoading={forgotApiLoading}
              variants="primary"
              className="w-full min-w-[150px] justify-center"
              type="submit"
              value="Submit"
            />
          </div>

          <Link
            to="/auth/login"
            className="text-blue-600 hover:underline hover:text-blue-700"
          >
            Back to Login Page
          </Link>
        </Form>
      </Formik>
    </div>
  );
};

export default ForgotPassword;
