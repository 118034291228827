export enum HEADER_ENUM {
  NAV_BUTTON = 'nav_button',
  TODAY_BUTTON = 'today_button',
  FILTER_BUTTON = 'filter_button',
  DAY_BUTTON = 'day_button',
  WEEK_BUTTON = 'week_button',
  FIVE_DAY_BUTTON = 'five_day_button',
  MONTH_BUTTON = 'month_button',
  CALENDAR_HEADER = 'calendar_header',
  FILTER_VIEW = 'filter_view',
  FILTER_LAYOUT = 'filter_layout',
  MONTH_BUTTON_ACTIVE = 'month_button_active',
  WEEK_BUTTON_ACTIVE = 'week_button_active',
  FIVE_DAY_BUTTON_ACTIVE = 'five_day_button_active',
  PREVIOUS = 'previous',
  TOOLTIP_CONTAINER = 'tooltip-container',
}

export enum API_URL {
  TIMING_GROUPS = '/session-creation/timing-groups',
  LIST = '/session-creation/list',
  SESSION_CREATION = '/session-creation',
  SERVICES = '/services',
  TEACHER_FILTER = '/session-creation/teacher-filter',
  STUDENT_FILTER = '/session-creation/student-filter',
  COURSE_FILTER = '/session-creation/course-filter',
  CUSTOM_GENERATION = '/session-creation/custom-generated-session',
  STUDENTS = '/students',
  TEACHERS = '/teachers',
  CLASSROOMS = '/classrooms',
  SUBJECTS = '/subjects',
  SESSION_TRANSFER = '/session-creation/session-transfer',
  AVAILABLE_UPDATE = '/session-creation/availability-update',
  AVAILABLE_CREATE = '/session-creation/availability-create',
  SCHEDULE_PROCESS_LOG = '/schedule-process-logs',
}

export enum FILTER_TITLE {
  CALENDAR = 'Calendar',
  LIST = 'List',
  VIEW_BY = 'View by',
  CALENDER_VIEW = 'Calender View',
  EXISTING = 'Existing',
  SOURCE_SCHEDULE = 'Source Schedule',
  CONTROLS = 'Controls',
  CLOSE_PANEL = 'Close panel',
  OPTIMIZED = 'Optimized',
  VIEW_TYPE = 'View Type',
  AVAILABILITY = 'Availability',
  CURRENT = 'Current',
  APPLY = 'Apply',
  SORT_TYPE = 'Sort Order',
}

export enum FORM_MODAL {
  CANCEL = 'Cancel',
  DELETE = 'Delete',
  ADD_EVENT = 'Add Event',
  SUBMIT = 'Submit',
  ACCEPT = 'Accept',
  REJECT = 'Reject',
  CREATE = 'Create',
}

export enum CALENDAR_SOURCE_SCHEDULE {
  EXISTING = 'existing',
  OPTIMIZED = 'optimized',
}

export enum CALENDAR_VIEW {
  CURRENT = 'current',
  AVAILABILITY = 'availability',
}

export enum SORT_TYPE {
  DEFAULT = 'Default',
  ASCENDING = 'Ascending',
  DESCENDING = 'Descending',
}
